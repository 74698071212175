<template>
  <div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 text-right">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#Modal-Contactos"
            v-if="$store.getters.can('admin.empresas.gestionarContactos')"
            @click="getModalData('Agregar')"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body table-responsive p-0">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-hover text-nowrap table-sm">
            <thead class="thead-dark">
              <tr>
                <th class="text-center">Estado</th>
                <th class="text-center">Tipo Contacto</th>
                <th class="text-center">Documento</th>
                <th class="text-center">Nombre</th>
                <th class="text-center">Cargo</th>
                <th class="text-center">Telefono</th>
                <th class="text-center">Telefono2</th>
                <th class="text-center">Email</th>
                <th class="text-center" style="width: 120px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="contacto in $parent.form.contactos" :key="contacto.id">
                <td class="text-center">
                  <span
                    class="badge"
                    style="cursor: pointer"
                    :class="
                      contacto.estado == 1 ? 'badge-success' : 'badge-danger'
                    "
                    @click="saveEstado(contacto)"
                  >
                    {{ contacto.nEstado }}
                  </span>
                </td>
                <td>{{ contacto.nTipoContacto }}</td>
                <td>{{ contacto.documento }}</td>
                <td>{{ contacto.nombre }}</td>
                <td>{{ contacto.cargo }}</td>
                <td>{{ contacto.telefono }}</td>
                <td>{{ contacto.telefono2 }}</td>
                <td>{{ contacto.email }}</td>
                <td class="text-right">
                  <div
                    class="btn-group"
                    v-if="
                      $store.getters.can('admin.empresas.gestionarContactos')
                    "
                  >
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#Modal-Contactos"
                      class="btn btn-sm bg-navy"
                      @click="getModalData('Editar', contacto)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- MODAL CONTACTOS -->
    <div
      class="modal fade"
      id="Modal-Contactos"
      v-if="$store.getters.can('admin.empresas.gestionarContactos')"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left">
            <h4 class="modal-title text-white">{{ modal.title }}</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Datos Principales -->
            <div class="row">
              <div class="form-group col-md-2">
                <label for="">Tipo Contacto</label>
                <select
                  class="form-control form-control-sm"
                  v-model="form.tipo_contacto"
                  :class="
                    $v.form.tipo_contacto.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_contacto in listasForms.tipo_contacto"
                    :key="tipo_contacto.numeracion"
                    :value="tipo_contacto.numeracion"
                  >
                    {{ tipo_contacto.descripcion }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-2">
                <label for="">Documento</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.documento"
                  :class="
                    $v.form.documento.$invalid ? 'is-invalid' : 'is-valid'
                  "
                />
              </div>
              <div class="form-group col-md-4">
                <label for="">Nombre</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.nombre"
                  :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="">Cargo</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="form.cargo"
                  :class="$v.form.cargo.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="">Telefono</label>
                <input
                  type="tel"
                  class="form-control form-control-sm"
                  v-model="form.telefono"
                  :class="$v.form.telefono.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-3">
                <label for="">Telefono 2</label>
                <input
                  type="tel"
                  class="form-control form-control-sm"
                  v-model="form.telefono2"
                />
              </div>
              <div class="form-group col-md-4">
                <label for="">Email</label>
                <input
                  type="email"
                  class="form-control form-control-sm"
                  v-model="form.email"
                  :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="!$v.form.$invalid"
          >
            <button type="button" class="btn btn-primary" @click="save()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "EmpresaDocumento",
  components: {},
  data() {
    return {
      empresa_id: this.$parent.form.id,
      modal: {
        title: "",
      },
      listasForms: {
        estados: [],
        tipo_contacto: [],
      },
      form: {
        id: null,
        empresa_id: null,
        nombre: null,
        documento: null,
        cargo: null,
        tipo_contacto: null,
        estado: null,
        telefono: null,
        telefono2: null,
        email: null,
      },
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      documento: {
        required,
      },
      cargo: {
        required,
      },
      tipo_contacto: {
        required,
      },
      telefono: {
        required,
      },
      email: {
        required,
        email
      },
    },
  },
  methods: {
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getNombreEstado(id) {
      const estado = this.listasForms.estados.find((l) => l.numeracion === id);
      return estado.descripcion;
    },

    getTipoContacto() {
      axios.get("/api/lista/118").then((response) => {
        this.listasForms.tipo_contacto = response.data;
      });
    },

    getNombreTipoContacto(id) {
      const tipo_contacto = this.listasForms.tipo_contacto.find(
        (l) => l.numeracion === id
      );
      return tipo_contacto.descripcion;
    },

    getModalData(accion, contacto = null) {
      this.modal.title = accion + " Contacto";
      this.resetForm();
      if (contacto) {
        this.form = {
          id: contacto.id,
          empresa_id: contacto.empresa_id,
          nombre: contacto.nombre,
          documento: contacto.documento,
          cargo: contacto.cargo,
          tipo_contacto: contacto.tipo_contacto,
          estado: contacto.estado,
          telefono: contacto.telefono,
          telefono2: contacto.telefono2,
          email: contacto.email,
        };
      }
    },

    resetForm() {
      this.form = {
        id: null,
        empresa_id: this.empresa_id,
        nombre: null,
        documento: null,
        cargo: null,
        tipo_contacto: null,
        estado: 1,
        telefono: null,
        telefono2: null,
        email: null,
      };
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.$parent.cargando = true;
        axios({
          method: "PUT",
          url: "/api/admin/empresas/saveContactos",
          data: this.form,
        })
          .then((response) => {
            this.$parent.form.contactos = response.data;
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          });
      }
    },

    saveEstado(contacto) {
      this.getModalData("Editar", contacto);
      let estado = 1;
      if (this.form.estado == 1) {
        estado = 2;
      }
      this.form.estado = estado;
      this.save();
    },
  },

  mounted() {
    this.getEstados();
    this.getTipoContacto();
  },
};
</script>
