<template>
  <div>
    <div class="card-body">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label for="tipos_eventos">Eventos Correos</label>
            <select
              class="form-control form-control-sm"
              v-model="cor.evento"
              :class="$v.cor.evento.$invalid ? 'is-invalid' : 'is-valid'"
              @change="validarCorreo"
            >
              <option value="">Seleccione...</option>
              <option
                v-for="evento in listasForms.eventos_correos"
                :key="evento.id"
                :value="evento.numeracion"
              >
                {{ evento.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label for="correo">Email</label>
            <input
              type="email"
              class="form-control form-control-sm"
              id="correo"
              v-model="cor.correo"
              :class="$v.cor.correo.$invalid ? 'is-invalid' : 'is-valid'"
              @blur="validarCorreo"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12">
          <div class="form-group">
            <label for="celular">Celular</label>
            <input
              type="number"
              class="form-control form-control-sm"
              id="celular"
              v-model="cor.celular"
              :class="$v.cor.celular.$invalid ? 'is-invalid' : 'is-valid'"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <br />
          <button
            type="button"
            @click="guardar_correos()"
            class="btn btn-primary"
            v-show="
              !$v.cor.$invalid && $store.getters.can('admin.empresas.agregarCorreo')
            "
          >
            <i class="fa fa-plus-circle"></i> Agregar
          </button>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12">
          <table
            id="docs"
            class="table table-bordered table-striped table-hover table-sm table-head-fixed text-nowrap"
          >
            <thead>
              <tr>
                <th>Evento</th>
                <th>Correo</th>
                <th>Celular</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in correos" :key="data.id">
                <td v-text="data.nEvento"></td>
                <td v-text="data.correo"></td>
                <td v-text="data.celular"></td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    @click="destroyCorreo(data.id)"
                    v-show="$store.getters.can('admin.empresas.eliminarCorreo')"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "EmpresaListaCorreo",
  data() {
    return {
      form: {},
      accion: "",
      metodo: "",
      cor: {
        evento: null,
        correo: null,
        celular: null,
      },
      listasForms: {
        eventos_correos: [],
      },
      modal: {
        title: "",
        accion: "",
      },
      correos: {},
    };
  },
  validations() {
    if (!this.cor.correo && !this.cor.celular) {
      return {
        cor: {
          evento: {
            required,
          },
          correo: {
            required,
            email,
          },
          celular: {
            required,
          },
        },
      };
    } else {
      return {
        cor: {
          evento: {
            required,
          },
          correo: {
            email,
          },
          celular: {},
        },
      };
    }
  },
  methods: {
    getEventosCorreos() {
      axios.get("/api/lista/4").then((response) => {
        this.listasForms.eventos_correos = response.data;
      });
    },

    getCorreos() {
      axios
        .get("/api/admin/listasCorreos", {
          params: {
            trans: this.$parent.form.id,
          },
        })
        .then((response) => {
          this.correos = response.data;
        });
    },

    validarCorreo() {
      if (this.cor.correo && this.cor.evento) {
        this.correos.forEach((element) => {
          if (this.cor.correo === element.correo && this.cor.evento === element.evento) {
            this.$swal({
              icon: "error",
              title: `El correo digitado ya se encuentra registrado en la lista con el evento ${element.nEvento} ...`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cor.correo = null;
          }
        });
      }
    },

    guardar_correos() {
      this.cor.empresa_id = this.$parent.form.id;
      if (!this.$v.cor.$invalid) {
        let metodo = "POST";
        axios({
          method: metodo,
          url: "/api/admin/listasCorreos",
          data: this.cor,
        })
          .then((response) => {
            this.cor = {};
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getCorreos();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyCorreo(idListaCorreo) {
      this.$swal({
        title: "Esta seguro de eliminar este correo?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/listasCorreos/" + idListaCorreo).then(() => {
            this.getCorreos();
            this.$swal({
              icon: "success",
              title: "Se eliminó el correo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
  },

  created() {
    this.getEventosCorreos();
    this.getCorreos();
  },
};
</script>
