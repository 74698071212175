<template>
  <div>
    <div class="card-body">
      <!-- Formulario -->
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="departamento">Departamento</label>
            <select
              class="form-control form-control-sm"
              v-model="form.departamento_id"
              id="departamento"
              :class="
                $v.form.departamento_id.$invalid ? 'is-invalid' : 'is-valid'
              "
            >
              <option value="">Seleccione...</option>
              <option
                v-for="departamento in listasForms.departamentos"
                :key="departamento.id"
                :value="departamento.id"
              >
                {{ departamento.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="corporacion">Corporacion</label>
            <select
              class="form-control form-control-sm"
              v-model="form.corporacion"
              id="corporacion"
              :class="$v.form.corporacion.$invalid ? 'is-invalid' : 'is-valid'"
            >
              <option value="">Seleccione...</option>
              <option
                v-for="corporacion in listasForms.corporaciones"
                :key="corporacion.numeracion"
                :value="corporacion.numeracion"
              >
                {{ corporacion.descripcion }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label for="fecha_inicial">Fecha inicial</label>
            <input
              type="date"
              class="form-control form-control-sm"
              id="fecha_inicial"
              v-model="form.fecha_inicial"
              :class="
                $v.form.fecha_inicial.$invalid ? 'is-invalid' : 'is-valid'
              "
              @input="validaFechas"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label for="fecha_final">Fecha final</label>
            <input
              type="date"
              class="form-control form-control-sm"
              id="fecha_final"
              v-model="form.fecha_final"
              :class="$v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'"
              @input="validaFechas"
            />
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="observacion">Observaciones</label>
          <br />
          <textarea
            vtype="date"
            class="form-control"
            id="fecha_final"
            v-model="form.observacion"
            rows="2"
            :class="$v.form.observacion.$invalid ? 'is-invalid' : 'is-valid'"
          ></textarea>
        </div>
        <div class="form-group col-md-6">
          <input
            type="file"
            id="file"
            ref="file"
            class="form-control-file"
            accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
            @change="elegirDirectorio()"
          />
          <br />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <br />
          <button
            type="button"
            @click="saveDocumento()"
            class="btn btn-primary"
            v-show="!$v.form.$invalid"
          >
            <i class="fa fa-plus-circle"></i> Agregar
          </button>
        </div>
      </div>
      <!-- Lista de Planes de Contingencias -->
      <div class="row">
        <div class="col-md-12">
          <table id="docs" class="table table-sm table-hover">
            <thead class="thead-dark">
              <tr>
                <th>N°</th>
                <th>Departamento</th>
                <th>Corporacion</th>
                <th>Fecha inicial</th>
                <th>Fecha final</th>
                <th>Estado</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in planeaciones" :key="data.id">
                <td v-text="index + 1"></td>
                <td v-text="data.departamento.descripcion"></td>
                <td v-text="data.nCorporacion"></td>
                <td v-text="data.fecha_inicial"></td>
                <td v-text="data.fecha_final"></td>
                <td class="text-center">
                  <span
                    v-if="
                      $store.getters.can(
                        'admin.empresas.cambiarEstadoPlanContingencia'
                      )
                    "
                    data-toggle="modal"
                    data-target="#modal-form-estado"
                    style="cursor: pointer"
                    class="badge"
                    :class="[
                      data.estado == 1
                        ? 'badge-warning'
                        : data.estado == 2
                        ? 'badge-success'
                        : 'badge-danger',
                    ]"
                    @click="datosModal(data)"
                  >
                    {{ data.nEstado }}
                  </span>
                  <span
                    v-else
                    class="badge"
                    :class="[
                      data.estado == 1
                        ? 'badge-warning'
                        : data.estado == 2
                        ? 'badge-success'
                        : 'badge-danger',
                    ]"
                  >
                    {{ data.nEstado }}
                  </span>
                </td>
                <td>
                  <div class="btn-group float-right">
                    <button
                      class="btn bg-primary"
                      v-if="data.link_documento"
                      @click="downloadWithAxios(uri_docs + data.link_documento)"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      @click="destroyPlaneacion(data.id)"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" ref="modalEstado" id="modal-form-estado">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">Cambiar estado</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="estado">Estado</label>
                <select
                  class="form-control form-control-sm"
                  v-model="formEstado.estado"
                  :class="
                    $v.formEstado.estado.$invalid ? 'is-invalid' : 'is-valid'
                  "
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="estado in listasForms.estados"
                    :key="estado.numeracion"
                    :value="estado.numeracion"
                  >
                    {{ estado.descripcion }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-if="
                  $store.getters.can(
                    'admin.empresas.cambiarEstadoPlanContingencia'
                  ) && !$v.formEstado.$invalid
                "
                @click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { required, email } from "vuelidate/lib/validators";
export default {
  name: "EmpresaPlanContingencia",

  data() {
    return {
      file: null,
      planeaciones: {},
      link: null,
      form: {
        empresa_id: null,
        departamento_id: null,
        corporacion: null,
        fecha_inicial: null,
        fecha_final: null,
        observacion: null,
        estado: null,
        link_documento: null,
      },
      formEstado: {
        id: null,
        estado: null,
      },
      accion: "",
      metodo: "",
      departamento: [],
      listasForms: {
        corporaciones: [],
        estados: [],
        departamentos: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {
    form: {
      empresa_id: {
        required,
      },
      departamento_id: {
        required,
      },
      corporacion: {
        required,
      },
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      observacion: {
        required,
      },
    },
    formEstado: {
      id: {
        required,
      },
      estado: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.form.departamento_id = null;
      this.form.corporacion = null;
      this.form.fecha_inicial = null;
      this.form.fecha_final = null;
      this.form.observacion = null;
      this.form.estado = 1;
      this.form.empresa_id = this.$route.params.id;
      document.getElementById("file").value = "";
      axios
        .get("/api/admin/planesContingencias?page=" + page, {
          params: { empresa_id: this.form.empresa_id },
        })
        .then((response) => {
          this.planeaciones = response.data;
        });
    },

    getDepartamentos() {
      axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },

    getCorporaciones() {
      axios.get("/api/lista/50").then((response) => {
        this.listasForms.corporaciones = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/51").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    validaFechas() {
      if (this.form.fecha_inicial && this.form.fecha_final) {
        const fecha_menor = new Date(this.form.fecha_inicial);
        const fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    datosModal(data) {
      this.formEstado.id = data.id;
      this.formEstado.estado = data.estado;
    },

    limpiarForm() {
      this.form.departamento_id = null;
      this.form.corporacion = null;
      this.form.fecha_inicial = null;
      this.form.fecha_final = null;
      this.form.fecha_inicial = null;
      this.form.observacion = null;
      this.form.estado = 1;
      this.form.link_documento = null;
    },

    save() {
      if (!this.$v.formEstado.$invalid) {
        let metodo = "PUT";
        axios({
          method: metodo,
          url: "/api/admin/planesContingencias/savePlanContingencia/",
          data: this.formEstado,
        })
          .then((response) => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo... " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyPlaneacion(idPlaneacion) {
      this.$swal({
        title: "Está seguro de eliminar este plan de contingecia?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/admin/planesContingencias/" + idPlaneacion)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "El Plan de Contingencia se eliminó exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    elegirDirectorio() {
      this.file = this.$refs.file.files[0];
    },

    saveDocumento() {
      var vm = this;
      vm.form.empresa_id = vm.$parent.form.id;
      let formData = new FormData();
      formData.append("documento", vm.file);
      formData.append("empresa_id", vm.form.empresa_id);
      formData.append("departamento_id", vm.form.departamento_id);
      formData.append("corporacion", vm.form.corporacion);
      formData.append("fecha_inicial", vm.form.fecha_inicial);
      formData.append("fecha_final", vm.form.fecha_final);
      formData.append("observacion", vm.form.observacion);
      formData.append("estado", vm.form.estado);

      axios
        .post("api/admin/planesContingencias/documento", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    downloadWithAxios(url) {
      window.open(url, "_blank");
    },
  },

  mounted() {
    this.getEstados();
    this.getCorporaciones();
    this.getDepartamentos();
    this.getIndex();
  },
};
</script>
